import React from "react"
import { graphql } from "gatsby"
import { Box } from "@chakra-ui/react"

import Seo from "@/components/Seo"

const YaraFarmerExpertSignupSurvey = () => {
  return (
    <Box>
      <Seo title="Yara Farmer Expert Signup Survey" />
      <iframe
        title="Yara Farmer Expert Signup Survey"
        src="https://survey.alchemer.eu/s3/90430581/Yara-Farmer-Expert-Signup"
        frameBorder="0"
        width="100%"
        height="100%"
        style={{ overflow: "hidden", minHeight: "100vh" }}
      ></iframe>
    </Box>
  )
}

export default YaraFarmerExpertSignupSurvey

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["userresearch"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
